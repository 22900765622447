import React from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';

const Love = () => (
  <Layout>
    <Sidebar showTabs="ramadan" />

    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="top"
    >
      <ol>
        <Link to="/grief/">
          <h5>GRIEF, STRESS, DEPRESSION, ANXIETY </h5>
        </Link>
        <div>
          <ol>
            <li>
              In Bukhari and Muslim it is narrated that the Messenger of Allah
              (May peace and blessings of Allah be upon him) used to say for
              GRIEF, STRESS, DEPRESSION, ANXIETY:
              <ul>
                <li>
                  <h6>Arabic:</h6>لَا إِلَهَ إِلَّا اللَّهُ الْعَظِيمُ
                  الْحَلِيمُ لَا إِلَهَ إِلَّا اللَّهُ رَبُّ الْعَرْشِ
                  الْعَظِيمِ لَا إِلَهَ إِلَّا اللَّهُ رَبُّ السَّمَاوَاتِ
                  وَرَبُّ الْأَرْضِ رَبُّ الْعَرْشِ الْكَرِيم
                </li>
                <li>
                  <h6>English:</h6>
                  “There is no god but God, the Incomparably Great, the Clement;
                  there is no god but God, the Lord of the mighty Throne; there
                  is no god but God, the Lord of the heavens, the Lord of the
                  earth, and the Lord of the noble Throne.”
                </li>
                <li>
                  <h6>Transletration:</h6>
                  Lā ilāha illallāh al-`Aẓīmul-Ḥalīm, lā ilāha illallāh Rabbu
                  ‘l-`Arshi ‘l-'Aẓīm, lā ilāha illallāh Rabbus-samāwāti wa Rabbu
                  ‘l-arḍ wa Rabbu ‘l-`Arshi ‘l-Karīm.
                </li>
              </ul>
            </li>
            <p />
            <li>
              The Prophet (May peace and blessings of Allah be upon him) said:
              the supplication of those afflicted by distress,
              <ul>
                <li>
                  <h6>Arabic:</h6>
                  اللّهُـمَّ رَحْمَتَـكَ أَرْجـو فَلا تَكِلـني إِلى نَفْـسي
                  طَـرْفَةَ عَـيْن، وَأَصْلِـحْ لي شَأْنـي كُلَّـه لَا إِلَهَ
                  إِلَّا أنْـت
                </li>
                <li>
                  <h6>English:</h6>
                  Allāhumma raḥmataka arjū falā takilnī ilā nafsī ṭarfata `ayn,
                  wa aṣliḥ lī sha'nī kullah, lā ilāha illā ant.
                </li>
                <li>
                  <h6>Transletration:</h6>
                  Allāhumma raḥmataka arjū falā takilnī ilā nafsī ṭarfata `ayn,
                  wa aṣliḥ lī sha'nī kullah, lā ilāha illā ant.
                </li>
              </ul>
            </li>
            <p />
            <li>
              The Prophet (May peace and blessings of Allah be upon him) said:
              Whenever a sadness, grief, or anxiety strikes a slave and the
              slave then says The following, Then Allah will remove his grief
              <ul>
                <li>
                  <h6>Arabic:</h6>اللّهُـمَّ إِنِّي عَبْـدُكَ ابْنُ عَبْـدِكَ
                  ابْنُ أَمَتِـكَ نَاصِيَتِي بِيَـدِكَ مَاضٍ فِيَّ حُكْمُكَ
                  عَدْلٌ فِيَّ قَضَاؤكَ أَسْأَلُـكَ بِكُلِّ اسْمٍ هُوَ لَكَ
                  سَمَّـيْتَ بِهِ نَفْسَكَ أِوْ أَنْزَلْتَـهُ فِي كِتَابِكَ أَوْ
                  عَلَّمْـتَهُ أَحَداً مِنْ خَلْقِـكَ أَوِ اسْتَـأْثَرْتَ بِهِ
                  فِي عِلْمِ الغَيْـبِ عِنْـدَكَ أَنْ تَجْـعَلَ القُرْآنَ
                  رَبِيـعَ قَلْبِـي وَنورَ صَـدْرِي وجَلَاءَ حُـزْنِي وذَهَابَ
                  هَمِّـي
                </li>
                <li>
                  <h6>English:</h6>O Allah, I am Your slave, and the son of Your
                  male slave, and the son of your female slave. My forehead is
                  in Your Hand (i.e. you have control over me). Your Judgment
                  upon me is assured, and Your Decree concerning me is just. I
                  ask You by every Name that You have named Yourself with,
                  revealed in Your Book, taught any one of Your creation, or
                  kept unto Yourself in the knowledge of the unseen that is with
                  You, to make the Qur'an the spring of my heart, and the light
                  of my chest, the banisher of my sadness, and the reliever of
                  my distress.
                </li>
                <li>
                  <h6>Transletration:</h6>Allāhumma innī `abduk, ibnu `abdik,
                  ibnu amatik, nāsiyatī biyadik, māḍin fiyya ḥukmuk, `adlun
                  fiyya qaḍā'uk, as'aluka bikullis’min huwa lak, sammayta bihi
                  nafsak, aw anzaltahu fī kitābik, aw `allamtahu aḥadan min
                  khalqik, aw‘ista'tharta bihi fī `ilmil-ghaybi `indak, an
                  taj`ala ‘l-Qur'āna rabī`a qalbī, wa nūra ṣadrī, wa jalā'a
                  ḥuznī, wa dhahāba hammī.
                </li>
              </ul>
            </li>
            <p />
            <li>
              <ul>
                <li>
                  <h6>Arabic:</h6>اللّهُـمَّ إِنِّي أَعْوذُ بِكَ مِنَ الهَـمِّ
                  وَ الْحُـزْنِ، والعًجْـزِ والكَسَلِ والبُخْـلِ والجُـبْنِ،
                  وضَلْـعِ الـدَّيْنِ وغَلَبَـةِ الرِّجال
                </li>
                <li>
                  <h6>English:</h6>O Allah, I seek refuge in you from grief and
                  sadness, from weakness and from laziness, from miserliness and
                  from cowardice, from being overcome by debt and overpowered by
                  men (i.e. others).
                </li>
                <li>
                  <h6>Transletration:</h6>Allāhumma 'innī 'a`ūdhu bika mina
                  ‘l-ḥammi wa ‘l-ḥuzn, wa ‘l-`ajzi wa ‘l-kasal, wa ‘l-bukhli wa
                  ‘l-jubn, wa ḍala`id-dayn, wa ghalabatir-rijāl.
                </li>
              </ul>
            </li>
            <p />
            <li>
              <ul>
                <li>
                  <h6>Arabic:</h6>لَا إِلَهَ إِلَّا أنْـت سُـبْحانَكَ إِنِّي
                  كُنْـتُ مِنَ الظّـالِميـن
                </li>
                <li>
                  <h6>English:</h6>There is none worthy of worship but You,
                  glory is to You. Surely, I was among the wrongdoers.
                </li>
                <li>
                  <h6>Transletration:</h6>Lā ilāha illā anta subḥānaka innī
                  kuntu minaẓ-ẓālimīn.
                </li>
              </ul>
            </li>
            <p />
            <li>
              <ul>
                <li>
                  <h6>Arabic:</h6>اللهُ اللهُ رَبِّي لا أُشْـرِكُ بِهِ شَيْـئاً
                </li>
                <li>
                  <h6>English:</h6>Allah, Allah is my Lord. I do not associate
                  anything with Him.
                </li>
                <li>
                  <h6>Transletration:</h6>Allāh, Allāhu Rabbī lā ushriku bihi
                  shay'a.
                </li>
              </ul>
            </li>
            <p />
            <li>
              <ul>
                <li>
                  <h6>Arabic:</h6>
                </li>
                <li>
                  <h6>English:</h6>
                </li>
                <li>
                  <h6>Transletration:</h6>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </ol>
    </section>
    <section></section>
  </Layout>
);

export default Love;
